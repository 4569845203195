import { addEnvironmentVariable, deleteEnvironmentVariable, FileEnvironmentVariable } from "@/services/files.service";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { RadixAppDialog } from "@components/library/AppDialog";
import { hlToastApiError } from "@components/library/Toast";
import { File } from "@/types/app/file";
import { useFileEnvironmentVariables } from "@/services/files.service";
import { useEffect, useState } from "react";
import { Eye, EyeClosed, Plus, Trash } from "@phosphor-icons/react";
import useConfirm from "@/context/useConfirm";
import IconButton from "@components/library/IconButton";
import Button from "@components/library/Button";
import { TextInput } from "@components/molecules/FormikInput";
import { classNames, generateId } from "@/lib/utils";

export const FileEnvironmentVariablesModal = ({
  file,
  open,
  setOpen,
}: {
  file: File;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { environmentVariables: envVariables, mutate } = useFileEnvironmentVariables(file.id);

  const confirm = useConfirm();

  // This holds the environment variables that are persisted in the database
  // and the ones that user has added but haven't been saved yet
  const [environmentVariables, setEnvironmentVariables] = useState<
    // ID is a helper to find the environment variable in the list
    { id: string; name: string | undefined; value: string | undefined; isPersisted: boolean }[]
  >([]);

  const [error, setError] = useState<string | undefined>(undefined);

  // Reset local state when modal opens
  useEffect(() => {
    if (open) {
      setEnvironmentVariables(
        envVariables?.map((env) => ({
          id: generateId(),
          isPersisted: true,
          name: env.name,
          value: env.value,
        })) ?? [],
      );
    }
  }, [open, envVariables]);
  return (
    <RadixAppDialog
      open={open}
      onClose={() => {
        setOpen(false);
        setEnvironmentVariables([]);
        setError(undefined);
      }}
      className="flex max-h-[600px] overflow-y-auto"
      title={"Environment Variables"}
      description={`Set environment variables for use in your Tool. These variables can be accessed in your code using the 'os' module.`}
    >
      <div className="flex flex-col gap-12">
        {environmentVariables.length > 0 ? (
          <div className="grid grid-cols-[160px_auto] gap-12">
            <div className="text-12-14 font-medium text-text-base-2">Key</div>
            <div className="text-12-14 font-medium text-text-base-2">Value</div>
          </div>
        ) : null}
        {environmentVariables.map((environmentVariable) => (
          <EnvironmentVariableRow
            key={environmentVariable.id}
            environmentVariable={environmentVariable}
            onNameChange={(id, name) => {
              setEnvironmentVariables(environmentVariables.map((env) => (env.id === id ? { ...env, name } : env)));
              setError(undefined);
            }}
            onValueChange={(id, value) => {
              setEnvironmentVariables(environmentVariables.map((env) => (env.id === id ? { ...env, value } : env)));
            }}
            onDelete={async (id) => {
              if (environmentVariable.isPersisted) {
                if (!environmentVariable.name) return;
                try {
                  await confirm({
                    title: `Are you sure you want to delete '${environmentVariable.name}' variable?`,
                    content: (
                      <>
                        This will delete this environment variable.
                        <br />
                        <span className="font-bold">This action cannot be undone.</span>
                      </>
                    ),
                    confirmationText: "Delete",
                    cancellationText: "Cancel",
                    confirmButtonProps: { shade: "red", styling: "solid", IconLeft: Trash },
                  });
                  await deleteEnvironmentVariable(file.id, environmentVariable.name);
                  hlToast({
                    title: `Environment variable '${environmentVariable.name}' deleted`,
                    variant: ToastVariant.Success,
                  });
                  mutate();
                } catch (e: any) {
                  if (e !== undefined) {
                    hlToastApiError({
                      error: e,
                      titleFallback: `Failed to delete environment variable '${environmentVariable.name}'`,
                    });
                  }
                }
              } else {
                setEnvironmentVariables(environmentVariables.filter((env) => env.id !== id));
              }
            }}
          />
        ))}
        {error ? <div className="text-12-14 text-text-danger-2">{error}</div> : null}
        <div className="flex items-center gap-8 pt-12">
          <Button
            size={24}
            onClick={() => {
              setEnvironmentVariables([
                ...environmentVariables,
                { id: generateId(), name: undefined, value: undefined, isPersisted: false },
              ]);
            }}
            IconLeft={Plus}
            styling="solid"
            shade="black"
          >
            Add
          </Button>
          {environmentVariables.some((env) => !env.isPersisted) ? (
            <Button
              size={24}
              disabled={environmentVariables.some((env) => !Boolean(env.name) || !Boolean(env.value))}
              onClick={async () => {
                // if there is a duplicate name, set the error
                const duplicateNames = environmentVariables
                  .filter((env) => env.name) // Only consider envs with names
                  .map((env) => env.name)
                  .filter((name, index, array) => array.indexOf(name) !== index);

                if (duplicateNames.length > 0) {
                  setError(`Duplicate environment variable name: ${duplicateNames[0]}`);
                  return;
                }
                try {
                  const environmentVariablesToAdd = environmentVariables
                    .filter((env) => Boolean(env.name) && Boolean(env.value) && !env.isPersisted)
                    .map((env) => ({
                      name: env.name,
                      value: env.value,
                    })) as FileEnvironmentVariable[];
                  await addEnvironmentVariable(file.id, environmentVariablesToAdd);
                  hlToast({
                    title: `Environment variable '${
                      environmentVariablesToAdd.length === 1 ? environmentVariablesToAdd[0].name : "s"
                    }' added`,
                    variant: ToastVariant.Success,
                  });
                  mutate();
                } catch (e: any) {
                  if (e !== undefined) {
                    hlToastApiError({
                      error: e,
                      titleFallback: `Failed to add environment variables`,
                    });
                  }
                }
              }}
              styling="solid"
              shade="black"
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
    </RadixAppDialog>
  );
};

const EnvironmentVariableRow = ({
  environmentVariable,
  onNameChange,
  onValueChange,
  onDelete,
}: {
  environmentVariable: { id: string; name: string | undefined; value: string | undefined; isPersisted: boolean };
  onNameChange: (id: string, name: string) => void;
  onValueChange: (id: string, value: string) => void;
  onDelete: (id: string) => void;
}) => {
  const [visibleSecrets, setVisibleSecrets] = useState<boolean>(environmentVariable.value === undefined);
  const hiddenPlaceholder = "••••••••••••••";
  return (
    <div className="flex items-center gap-12">
      <TextInput
        name="key"
        readOnly={environmentVariable.isPersisted}
        value={environmentVariable.name}
        autoFocus={!environmentVariable.isPersisted}
        onChange={(event) => {
          const sanitizedValue = event.target.value.replace(/\s/g, "");
          onNameChange(environmentVariable.id, sanitizedValue);
        }}
        className="w-160"
        placeholder="Key"
        type="text"
      />
      <TextInput
        name="value"
        readOnly={environmentVariable.isPersisted}
        value={visibleSecrets ? environmentVariable.value : hiddenPlaceholder}
        onChange={(event) => {
          const sanitizedValue = event.target.value.replace(/\s/g, "");
          onValueChange(environmentVariable.id, sanitizedValue);
        }}
        className="w-192"
        placeholder="Value"
        addOnRight={
          !environmentVariable.isPersisted ? null : (
            <IconButton
              Icon={visibleSecrets ? EyeClosed : Eye}
              size={24}
              onClick={() => {
                setVisibleSecrets(!visibleSecrets);
              }}
            />
          )
        }
        type={"text"}
      />
      <div className="flex items-center">
        <IconButton Icon={Trash} size={24} onClick={() => onDelete(environmentVariable.id)} />
      </div>
    </div>
  );
};
