import useElementDimensions from "@/lib/use-element-dimensions";
import { capitalizedFileType, useFile } from "@/services/files.service";
import { getIsLLMEvaluator } from "@/types/app/evaluator";
import Button from "@components/library/Button";
import { ContextMenuItem } from "@components/library/ContextMenu";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { ConditionalTooltip } from "@components/library/Tooltip";
import { InlineMenu } from "@components/SimpleTable/InlineMenu";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import CodeIcon from "@heroicons/react/outline/CodeIcon";
import { BookOpen, BracketsCurly, Copy, Gear, GearSix } from "@phosphor-icons/react";
import { parseAsBoolean, useQueryState } from "nuqs";
import { useState } from "react";
import { UseFileModal } from "../../../llama/Dashboard/UseFileModal";
import { MonitoringEvaluatorModal } from "../../../llama/Monitoring/MonitoringEvaluatorModal";
import { ReadmeButton, ReadmeContextMenu } from "../../../llama/Projects/ReadmePanel";
import { FileEnvironmentVariablesModal } from "@components/llama/Files/FileEnvironmentVariablesModal";

export const FileActions = ({ fileId }: { fileId: string }) => {
  const { file, mutate } = useFile({ fileId });
  const [showHowToUseDialog, setShowHowToUseDialog] = useState(false);

  // Store whether the monitoring evaluator modal is open in the query param, so we can link to it
  const [openMonitoringEvaluatorModal, setOpenMonitoringEvaluatorModal] = useQueryState(
    "monitoring",
    parseAsBoolean.withDefault(false),
  );

  /** Dimensions of the top bar where we show all buttons */
  const BREAKPOINT_0 = 390;
  /** Dimensions of the top bar where we also show the readme button */
  const BREAKPOINT_1 = 340;
  /** Dimensions of the top bar where we show only the monitoring button */
  const BREAKPOINT_2 = 260;

  // Get the width of the parent element that is growing to the available width
  const { dimensions, ref } = useElementDimensions();

  const monitoringPossible =
    (file && ["flow", "prompt", "tool"].includes(file?.type)) ||
    (file?.type === "evaluator" && getIsLLMEvaluator(file));

  const environmentVariablesPossible = file?.type === "tool";
  const [openEnvironmentVariablesModal, setOpenEnvironmentVariablesModal] = useState(false);
  return (
    <div ref={ref} className="flex flex-1 grow items-center justify-end gap-4 ">
      {!environmentVariablesPossible || (dimensions && dimensions.width < BREAKPOINT_0) ? null : (
        <Button
          size={24}
          shade={"gray"}
          disabled={!environmentVariablesPossible}
          onClick={() => setOpenEnvironmentVariablesModal(true)}
          IconLeft={BracketsCurly}
          styling="ghost"
        >
          Environment Variables
        </Button>
      )}
      {dimensions && dimensions.width < BREAKPOINT_1 ? null : (
        <ConditionalTooltip
          condition={!monitoringPossible}
          content={monitoringPossible ? "" : "Monitoring can only be connected to Prompts and Tools"}
        >
          <Button
            size={24}
            shade={"gray"}
            disabled={!monitoringPossible}
            onClick={() => setOpenMonitoringEvaluatorModal(true)}
            IconLeft={GearSix}
            styling="ghost"
          >
            Monitoring
          </Button>
        </ConditionalTooltip>
      )}
      {dimensions && dimensions.width < BREAKPOINT_2 ? null : <ReadmeButton fileId={file?.id} />}

      <InlineMenu dotsOrientation="horizontal">
        {dimensions && dimensions.width < BREAKPOINT_2 && (
          <ContextMenuItem
            withinDropdownMenu
            // TODO, add ths to the component.
            // tooltip={monitoringPossible ? "" : "Monitoring can only be connected to Prompts and Tools"}
            key="envvars"
            disabled={!environmentVariablesPossible}
            IconLeft={{ Icon: BracketsCurly }}
            onClick={() => setOpenEnvironmentVariablesModal(true)}
          >
            Environment Variables
          </ContextMenuItem>
        )}
        {dimensions && dimensions.width < BREAKPOINT_2 && (
          <ConditionalTooltip
            condition={!monitoringPossible}
            content={monitoringPossible ? "" : "Monitoring can only be connected to Prompts and Tools"}
          >
            <ContextMenuItem
              withinDropdownMenu
              // TODO, add ths to the component.
              // tooltip={monitoringPossible ? "" : "Monitoring can only be connected to Prompts and Tools"}
              key="monitoring"
              disabled={!monitoringPossible}
              IconLeft={{ Icon: Gear }}
              onClick={() => setOpenMonitoringEvaluatorModal(true)}
            >
              Configure Monitoring
            </ContextMenuItem>
          </ConditionalTooltip>
        )}
        {dimensions && dimensions.width < BREAKPOINT_1 && <ReadmeContextMenu fileId={file?.id} />}

        {file?.type === "prompt" ? (
          <ContextMenuItem withinDropdownMenu IconLeft={CodeIcon} onClick={() => setShowHowToUseDialog(true)}>
            Use API
          </ContextMenuItem>
        ) : (
          <ContextMenuItem
            withinDropdownMenu
            IconLeft={QuestionMarkCircleIcon}
            onClick={() => setShowHowToUseDialog(true)}
          >
            How to use {file ? capitalizedFileType(file?.type) : ""}
          </ContextMenuItem>
        )}

        <ContextMenuItem
          withinDropdownMenu
          key="delete"
          disabled={!file?.id}
          IconLeft={{ Icon: Copy }}
          onClick={() => {
            if (!file?.id) return;
            navigator.clipboard.writeText(file.id);
            hlToast({
              title: "Copied ID to clipboard",
              variant: ToastVariant.Success,
            });
          }}
        >
          Copy {file ? capitalizedFileType(file?.type) : "File"} ID
        </ContextMenuItem>
      </InlineMenu>
      {file && <UseFileModal file={file} open={showHowToUseDialog} onClose={() => setShowHowToUseDialog(false)} />}
      {monitoringPossible && (
        <MonitoringEvaluatorModal
          file={file}
          mutate={mutate}
          // Set it to null if false so we clear away the query param
          open={openMonitoringEvaluatorModal ?? false}
          setOpen={(open: boolean) => setOpenMonitoringEvaluatorModal(open ? open : null)}
        />
      )}
      {environmentVariablesPossible && (
        <FileEnvironmentVariablesModal
          file={file}
          open={openEnvironmentVariablesModal ?? false}
          setOpen={(open: boolean) => setOpenEnvironmentVariablesModal(open ? open : false)}
        />
      )}
    </div>
  );
};

export const DirectoryActions = ({ directoryId }: { directoryId: string }) => {
  return (
    <div className="flex flex-1 items-center justify-end gap-4">
      <ReadmeButton directoryId={directoryId} />
      <InlineMenu dotsOrientation="horizontal">
        <ContextMenuItem
          withinDropdownMenu
          key="delete"
          disabled={!directoryId}
          IconLeft={{ Icon: Copy }}
          onClick={() => {
            if (!directoryId) return;
            navigator.clipboard.writeText(directoryId);
            hlToast({
              title: "Copied ID to clipboard",
              variant: ToastVariant.Success,
            });
          }}
        >
          Copy Directory ID
        </ContextMenuItem>
      </InlineMenu>
    </div>
  );
};
